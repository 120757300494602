
















import { InputSetups } from '@/mixins/input-setups'
import { ListCondition } from "./ListCondition";

import ChipsInput from 'piramis-base-components/src/components/Pi/fields/ChipsInput/ChipsInput.vue';

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    ChipsInput
  }
})
export default class ListConditionView extends Mixins<InputSetups>(InputSetups) {
  @VModel() model!: ListCondition
}
