var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-condition"},[_c('chips-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.model,
        'key': 'values',
        'prefix': ("face_control_" + (_vm.model.type) + "_"),
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }